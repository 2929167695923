<template>
  <page-wrapper
      :isLoading="isLoading"
      :hasFilters="true"
      @submitFilters="GetAllWallets"
  >
    <template #title> Wallets List</template>
    <template #titleBtn>
      <b-button
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          class=""
          variant="success"
          @click="downloadFile"
      >
        Download Excel File
      </b-button>
    </template>
    <template #filters>
      <search-input
          @enter="GetAllWallets"
          class="w-100"
          v-model="walletsFilter.searchCommand"
          inputId="fullNameFilter"
          inputLabel="Search By Username"
          inputPlaceholder="s y"
      ></search-input>
      <b-col cols="12" md="3">

        <b-form-group label="Count" label-for="count">
          <v-select
              class="mr-md-2 w-auto"
              id="count"
              v-model="walletsFilter.count"
              :options="perPageOptions"
              :clearable="false"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="3">
        <b-form-group label="Fake Wallets" label-for="fakeWallets">
          <v-select
              class="mr-md-2 w-auto"
              id="fakeWallets"
              v-model="walletsFilter.isFake"
              :options="isFakeOptions"
              label="name"
              :reduce="name=> name.value"
          />
        </b-form-group>
      </b-col>
    </template>
    <custom-table
        :pageNumber="walletsFilter.pageNumber"
        :count="walletsFilter.count"
        :totalCount="totalCount"
        :tableItems="wallets"
        :tableColumns="myTableColumns"
        @changePage="changePage"
    >
      <template #userInfo="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
                size="45"
                :src="baseURL + data.item.userInfo.selfieFileData"
                :variant="`success`"
                :to="{
                name: 'apps-users-edit',
                params: { id: data.item.userInfo.userId },
              }"
            />
          </template>
          <b-link
              :to="{
              name: 'apps-users-edit',
              params: { id: data.item.userInfo.userId },
            }"
              class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.userInfo.userName }}
          </b-link>
          <small class="text-muted">@{{ data.item.userInfo.userId }}</small>
        </b-media>
      </template>

      <template #updateDate="data">
        {{ data.item.updateDate.slice(0, 10) }}
        -
        {{ data.item.updateDate.slice(11, 19) }}
      </template>
      <template #credit="data">
        <p>{{ parseFloat(data.item.credit) }} $</p>
      </template>
    </custom-table>
  </page-wrapper>
</template>

<script>
import {BAvatar, BButton, BCol, BFormGroup, BLink, BMedia, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import {WalletGetAllRequest} from "@/libs/Api/Wallet";
import Helper from "@/libs/Helper";
import {getUserData} from "@/auth/utils";
import axiosIns from "@/libs/axios";
import {saveAs} from "file-saver";
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import SearchInput from "@/views/components/Utilities/SearchInput.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";

export default {
  title: "Wallets List",
  name: "WalletsList",
  data() {
    return {
      wallets: null,
      totalCount: null,
      isLoading: false,
      walletsFilter: {
        pageNumber: 1,
        count: 10,
        searchCommand: null,
        isFake: null
      },
      baseURL: Helper.baseUrl + "media/gallery/profile/",
      perPageOptions: [10, 20, 30, 40, 50],
      isFakeOptions: [
        {
          name: 'Show',
          value: true
        },
        {
          name: 'Hide',
          value: false
        },
      ],
      myTableColumns: [
        {
          key: "userInfo",
          label: "User",
        },
        {
          key: "credit",
          label: "Credit",
        },
        {
          key: "updateDate",
          label: "Last Update",
        },
      ],
    };
  },
  async created() {
    await this.GetAllWallets();
  },
  methods: {
    changePage(pageNumber) {
      this.walletsFilter.pageNumber = pageNumber;
      this.GetAllWallets();
    },
    async GetAllWallets() {
      try {
        this.isLoading = true;
        let _this = this;
        let walletGetAllRequest = new WalletGetAllRequest(_this);
        walletGetAllRequest.setParams(_this.walletsFilter);
        await walletGetAllRequest.fetch(
            function (content) {
              _this.wallets = content.wallets;
              _this.totalCount = content.walletsCount;
            },
            function (error) {
              console.log(error);
            }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async downloadFile() {
      try {
        this.isLoading = true;
        let _this = this;
        let userToken = getUserData().data.token;
        await axiosIns
            .post(`Wallet/GetAllExportExcel`, null, {
              responseType: "blob",
              Authorization: userToken,
              params: {
                isFake: _this.walletsFilter.isFake,
                searchCommand: _this.walletsFilter.searchCommand
              }
            })
            .then((resp) => {
              _this.overlay = false;
              saveAs(resp.data, "wallets.xlsx");
            });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BFormGroup,
    BButton,
    BMedia,
    BAvatar,
    BLink,
    vSelect,
    BRow,
    BCol,
    PageWrapper,
    SearchInput,
    CustomTable,
  },
};
</script>

<style scoped></style>
